<template>
  <b-card>
    <b-row>
      <b-col cols="12"
             class="d-flex align-items-center"
             style="gap:10px;"
      >
        <h5>Select an input type for chatbot </h5>
        <div v-if="!disableInputOption"
             class="d-flex input-selection-types"
        >
          <div v-b-tooltip.hover="`Input type text`"
               class="input-selection-card"
               :class="inputType=='text' && 'selected'"
               @click="inputType='text'"
          >
            <feather-icon icon="TypeIcon"
                          size="20"
            />
          </div>
          <div v-b-tooltip.hover="`Input type audio`"
               class="input-selection-card"
               :class="inputType=='audio' && 'selected'"
               @click="inputType='audio'"
          >
            <feather-icon icon="MicIcon"
                          size="20"
            />
          </div>
        </div>
      </b-col>
    </b-row>
    <h5 class="my-1">
      Please select your chatbot character
    </h5>
    <b-row>
      <b-col
        v-for="(character,key) in characters "
        :key="key"
        lg="4"
        sm="6"
      >
        <div
          class="character-image d-flex justify-content-center align-items-center"
          :class="{'selected-character':key===selectedCharacter}"
        >
          <b-form-radio
            v-model="selectedCharacter"
            name="selected-character"
            class="p-0"
            style="margin:5px;"
            :disabled="isProcessing"
            :value="key"
          >
            <img :src="`${require(`@/assets/images/chatbot/${character}`)}`"
                 class="img-fluid"
            >
          </b-form-radio>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import { IDLE } from '@/const/chatbot'
import {
  BRow, BCol, BFormRadio, BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BFormRadio,
    BCard,
  },
  props: {
    disableInputOption: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      characters: IDLE,
      isProcessing: false,
    }
  },

  computed: {
    selectedCharacter: {
      get() {
        return this.$store.getters['appConfig/currentChatbotCharacter']
      },
      set(val) {
        this.$store.commit('appConfig/SET_CURRENT_CHATBOT_CHARACTER', val)
      },
    },
    inputType: {
      get() {
        return this.$store.getters['appConfig/currentChatbotInputType']
      },
      set(val) {
        this.$store.commit('appConfig/SET_CURRENT_CHATBOT_INPUT_TYPE', val)
      },
    },
  },
}
</script>

<style lang="scss">
  .input-selection-types{
    align-items: center;
    justify-content: center;
    gap:15px;

    .input-selection-card{
      width:35px;
      height:35px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #7367f0;
      cursor: pointer;
      color:#7367f0;
      border-radius: 5px;
      &.selected{
        background: #7367f0;
        border-color: #7367f0;
        color:white;
      }
    }
  }
</style>
